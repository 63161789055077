import {
  Avatar,
  Box,
  Divider,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type Commentaire from "models/Commentaire";
import { dateUtil } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import graphService from "services/graphService";
import { search } from "jmespath";
import ChatIcon from "icons/ChatIcon";
import fileUtil from "utils/fileUtil";

interface CommandeCommentairesProps {
  commentaires: Array<Commentaire>;
}

async function getAgents(ids: Array<string>, setter: (users: Array<User>) => void): Promise<void> {
  const auteurs = await graphService.getAgentsByIds(ids);

  for (const auteur of auteurs) {
    const res = await graphService.getPhotoByUser(auteur.id);
    const base64data = await fileUtil.readFileAsDataURL(res);

    if (base64data != null) {
      if (typeof base64data === "string") {
        auteur.avatar = base64data.toString();
      } else {
        const utf8decoder = new TextDecoder();
        auteur.avatar = utf8decoder.decode(base64data);
      }
    }
  }

  setter(auteurs);
}

function CommandeCommentaires({ commentaires }: CommandeCommentairesProps): ReactElement {
  const [auteurs, setAuteurs] = useState<Array<User>>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (commentaires.length !== 0) {
      const auteurIds: Array<string> = search(commentaires, "[*].auteur");

      if (auteurIds != null) {
        void getAgents(auteurIds, setAuteurs);
      }
    }
  }, [commentaires]);

  if (commentaires.length === 0) {
    return <></>;
  }

  return (
    <Grid size={12}>
      <Divider sx={{ marginTop: -1.5, marginBottom: 1.5 }} />
      <List>
        {commentaires.map((commentaire) => {
          const auteur = auteurs.find((user) => user.id === commentaire.auteur);
          return (
            <ListItem alignItems="flex-start" sx={{ p: 0 }} key={commentaire.commentaireId}>
              <ListItemAvatar>
                <Avatar alt={auteur?.displayName} src={auteur?.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant={isSmallScreen ? "body2" : "body1"}
                    sx={{
                      mb: 1,
                      flexDirection: "column",
                      display: isSmallScreen ? "flex" : "initial",
                    }}>
                    {auteur?.displayName}{" "}
                    <Typography component="span" variant="body2">
                      {`${auteur?.jobTitle} - ${dateUtil.format(commentaire.date, "dd/MM/yyyy")}`}
                    </Typography>
                  </Typography>
                }
                secondary={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>
                      <Typography
                        component="span"
                        variant={isSmallScreen ? "body2" : "body1"}
                        sx={{
                          color: "text.primary",
                          display: "inline",
                          whiteSpace: "break-spaces",
                        }}>
                        {commentaire.value}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        color="primary"
                        href={`https://teams.microsoft.com/l/chat/0/0?users=${auteur?.mail}`}
                        target="_blank">
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            pr: 1,
                            fontSize: isSmallScreen ? "0.7rem" : "0.8rem",
                            color: "text.primary",
                          }}
                          component="span"
                          variant="caption">
                          Envoyer un message via Teams
                        </Typography>
                        <ChatIcon sx={{ fontSize: isSmallScreen ? "1.3rem" : "1.5rem" }} />
                      </IconButton>
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}

export default CommandeCommentaires;
