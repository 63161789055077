import type { ReactElement } from "react";
import { Button, CircularProgress, Container, Fade, Grid2 as Grid } from "@mui/material";
import { authenticationService } from "@sdeapps/react-core";

/**
 * Page appelée si l'utilisateur n'est pas connecté; lance la connexion
 */
function ConnectionPage(): ReactElement {
  function login(): void {
    void authenticationService.login();
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Grid sx={{ marginTop: 2 }} size={12}>
          <CircularProgress />
        </Grid>
        <Grid size={12}>
          <p>Connexion en cours...</p>
        </Grid>
        <Grid size={12}>
          {/* Petite sécurité supplémentaire : Un bouton qui apparaît
              au bout de 3s pour lancer manuellement la connexion */}
          <Fade style={{ transitionDelay: `${3000}ms` }} timeout={1000} easing="ease-out" in>
            <Button onClick={login} variant="contained">
              Connexion manuelle
            </Button>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ConnectionPage;
