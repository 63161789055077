import {
  Card,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ReactElement } from "react";

function CommandeCardSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2} size={12}>
          <Grid container spacing={3} size={12} sx={{ alignItems: "center" }}>
            <Grid size="auto">
              <Skeleton animation="wave" variant="rounded" height={50} width={50} />
            </Grid>
            <Grid size="grow">
              <Typography variant={isSmallScreen ? "body1" : "subtitle2"}>
                <Skeleton animation="wave" />
              </Typography>
              <Skeleton animation="wave" width={100} />
            </Grid>
          </Grid>
          {[...Array(5)].map((v, i) => (
            <Grid key={i} size={12}>
              <Skeleton animation="wave" />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CommandeCardSkeleton;
