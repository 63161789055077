import { appiConfig, authenticationConfig, config } from "config/app-config";
import "App.css";
import ErrorPage from "pages/errors/ErrorPage";
import { type ReactElement } from "react";
import { RouterProvider } from "react-router-dom";
import authenticatedRouter from "routes/authenticatedRouter";
import unAuthenticatedRouter from "routes/unAuthenticatedRouter";
import { AppInsightsProvider, AuthenticationProvider } from "@sdeapps/react-core";
import EpicThemeProvider from "providers/EpicThemeProvider";
import SdeappsSnackbarProvider from "providers/SdeappsSnackbarProvider";

function App(): ReactElement {
  return (
    <div className="App">
      <EpicThemeProvider>
        <SdeappsSnackbarProvider>
          <AppInsightsProvider
            onError={ErrorPage}
            connectionString={appiConfig.connectionString}
            appVersion={config.appVersion}>
            <AuthenticationProvider
              config={authenticationConfig}
              unauthorizedPage={<RouterProvider router={unAuthenticatedRouter} />}>
              <RouterProvider router={authenticatedRouter} />
            </AuthenticationProvider>
          </AppInsightsProvider>
        </SdeappsSnackbarProvider>
      </EpicThemeProvider>
    </div>
  );
}

export default App;
