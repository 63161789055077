import { Grid2 as Grid, Button, Chip, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { routesConfig } from "config/app-config";
import SquareImage from "components/atoms/SquareImage";
import { Authorization } from "@sdeapps/react-core";
import CartActions from "components/molecules/CartActions";
import PageTitle from "components/molecules/PageTitle";
import ApplicationRoles from "constants/ApplicationRoles";
import type Article from "models/Article";
import type { ReactElement } from "react";

interface ArticleInformationTemplateProps {
  article: Article;
}

function ArticleInformationTemplate({ article }: ArticleInformationTemplateProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <PageTitle title={article.label} />
      <Container sx={{ mb: 4 }}>
        <Grid container spacing={2} size={12}>
          <Authorization roles={ApplicationRoles.SSTMANAGER}>
            <Grid size={12}>
              <Button
                href={routesConfig.modifyArticle.getParameterPath(article.id)}
                variant="contained">
                Modifier l'article
              </Button>
            </Grid>
          </Authorization>
          <Grid
            container
            spacing={2}
            size={{ md: 3, xs: 12 }}
            sx={{ alignContent: "center", justifyContent: "center" }}>
            <Grid container size={12} sx={{ justifyContent: "center", position: "relative" }}>
              <SquareImage
                image={article.photo.sasUri}
                width={isSmallScreen ? "120px" : "140px"}
                withBackground
              />
            </Grid>
            {article.informations?.Habilitation != null && (
              <Grid>
                <Chip label={<Typography>Habilitation requise</Typography>} color="error" />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} size={{ md: 9, xs: 12 }}>
            <Grid size={12}>
              <Typography sx={{ whiteSpace: "break-spaces" }}>{article.description}</Typography>
            </Grid>
            <CartActions article={article} alignContent="flex-end" justifyContent="flex-end" />
          </Grid>
          {Object.keys(article.informations).length > 0 &&
            Object.values(article.informations).some((v, i) => v != null && v !== "") && (
              <>
                <Grid size={12}>
                  <Typography variant="h3" color="primary">
                    Informations pratiques
                  </Typography>
                </Grid>
                {Object.keys(article.informations).map(
                  (value) =>
                    article.informations[value] !== "" && (
                      <Grid container key={value} size={12}>
                        <Grid size={{ xs: 12, md: 2 }}>
                          <Typography>
                            <strong>{value}</strong>
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 10 }}>
                          <Typography sx={{ whiteSpace: "break-spaces" }}>
                            {article.informations[value]}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                )}
              </>
            )}
        </Grid>
      </Container>
    </>
  );
}

export default ArticleInformationTemplate;
