import { Box, type SvgIconProps } from "@mui/material";
import { type ReactElement } from "react";
import { type BoxProps } from "@mui/system";
import { type StatutAssociation } from "constants/SatutMap";

interface CommandeStatutIconProps {
  boxProps?: Omit<BoxProps, "sx">;
  svgIconProps?: SvgIconProps;
  statutAssociation?: StatutAssociation;
}

function CommandeStatutIcon({
  boxProps,
  svgIconProps,
  statutAssociation,
}: CommandeStatutIconProps): ReactElement | null {
  if (statutAssociation == null) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 32,
        height: 32,
        backgroundColor: `${statutAssociation.color}.main`,
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...boxProps}>
      <statutAssociation.icon
        viewBox="0 0 24 24"
        sx={{ color: "white" }}
        fontSize="small"
        {...svgIconProps}
      />
    </Box>
  );
}

export default CommandeStatutIcon;
