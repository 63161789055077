import {
  Card,
  CardActions,
  CardContent,
  Grid2 as Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import type Article from "models/Article";
import { memo } from "react";
import type { ReactElement } from "react";
import { routesConfig } from "config/app-config";
import CartActions from "components/molecules/CartActions";
import SquareImage from "components/atoms/SquareImage";

type ArticleCardProps = {
  article: Article;
  addToCartUI?: boolean;
  isContentLink?: boolean;
};

const ArticleCard = memo(function ArticleCard({
  article,
  addToCartUI = false,
  isContentLink = false,
}: ArticleCardProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const MAX_DESCRIPTION_LINE_NUMBER = isSmallScreen ? 3 : 4;

  const descriptionSx: SxProps<Theme> = (theme: Theme) => {
    const lineHeightPx =
      (theme.typography.body1.lineHeight as number) * theme.typography.htmlFontSize;
    return {
      lineClamp: `${MAX_DESCRIPTION_LINE_NUMBER}`,
      WebkitLineClamp: MAX_DESCRIPTION_LINE_NUMBER,
      boxOrient: "vertical",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      overflow: "hidden",
      maxHeight: `${lineHeightPx * MAX_DESCRIPTION_LINE_NUMBER}px`,
    };
  };

  return (
    <Card variant="outlined" sx={{ border: "none" }}>
      <CardContent
        {...(isContentLink && {
          sx: { color: "inherit", textDecoration: "none" },
          component: Link,
          href: routesConfig.catalogArticle.getParameterPath(article.id),
        })}>
        <Grid container spacing={isSmallScreen ? 1 : 2} size={12} sx={{ alignItems: "flex-start" }}>
          <Grid
            sx={{ position: "relative", m: "none" }}
            {...(isSmallScreen && { container: true, justifyContent: "center" })}>
            <SquareImage
              image={article.photo.sasUri}
              width={isSmallScreen ? "120px" : "140px"}
              withBackground
            />
          </Grid>
          <Grid size={{ xs: 12, sm: "grow" }}>
            <Typography sx={{ fontWeight: 500 }} color="primary">
              {article.label}
            </Typography>
            <Typography sx={descriptionSx}>{article.description}</Typography>
            <Link
              sx={{ fontWeight: 500, fontFamily: "Poppins, Roboto, sans serif" }}
              {...(isContentLink && {
                component: Typography,
              })}
              href={routesConfig.catalogArticle.getParameterPath(article.id)}>
              voir la fiche
            </Link>
          </Grid>
        </Grid>
      </CardContent>
      {addToCartUI && (
        <CardActions>
          <CartActions article={article} justifyContent="flex-end" />
        </CardActions>
      )}
    </Card>
  );
});

export default ArticleCard;
