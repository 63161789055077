import {
  Divider,
  Grid2 as Grid,
  Step,
  type StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { dateUtil } from "@sdeapps/react-core";
import StatutButtonGroup from "components/atoms/buttons/StatutButtonGroup/StatutButtonGroup";
import statutMap, { magasinierArticleStatutOptions } from "constants/SatutMap";
import Statut from "constants/Statut";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";
import type { ReactElement, ReactNode } from "react";

const StatutStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(() => ({
  color: "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function StatutStepIcon(props: Readonly<StepIconProps>): ReactNode {
  const { active, className } = props;

  return (
    <StatutStepIconRoot ownerState={{ active }} className={className}>
      <div className="QontoStepIcon-circle" />
    </StatutStepIconRoot>
  );
}

interface ArticleListProps {
  articlesList: Array<CommandeArticleFormModel>;
  setArticlesList: (c: Array<CommandeArticleFormModel>) => void;
}

function ArticleListStore({ articlesList, setArticlesList }: ArticleListProps): ReactElement {
  function updateStatut(newStatut: Statut, articleIndex: number): void {
    const tempArticleList = [...articlesList];
    tempArticleList[articleIndex].statutArticle = newStatut;
    setArticlesList(tempArticleList);
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      size={12}
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
      }}>
      <Grid size={12}>
        <Divider />
      </Grid>
      {articlesList.map((article, i) => {
        return (
          article.statutArticle !== Statut.REFUSE && (
            <Grid
              container
              spacing={2}
              key={article.articleId}
              size={12}
              sx={{ alignItems: "center" }}>
              <Grid size={{ xs: 12, lg: "grow" }}>
                <Typography>{article.articleLabel}</Typography>
              </Grid>
              <Grid size={{ xs: "auto", lg: 1 }}>
                <Typography>
                  <strong>Qté</strong> : {article.quantite}
                </Typography>
              </Grid>
              <Grid size={{ xs: "grow", lg: 2 }}>
                <Typography>
                  <strong>Taille</strong> : {article.taille}
                </Typography>
              </Grid>
              <Grid container size={{ xs: 12, md: "auto" }} sx={{ justifyContent: "flex-end" }}>
                <StatutButtonGroup
                  onChangeStatut={(newStatut?: Statut) => {
                    if (newStatut != null) {
                      updateStatut(newStatut, i);
                    }
                  }}
                  value={article.statutArticle}
                  statutOptions={magasinierArticleStatutOptions}
                />
              </Grid>
              <Grid size={12}>
                <Stepper alternativeLabel>
                  {article.statutHistories?.map((x) => {
                    return (
                      <Step key={x.statut}>
                        <StepLabel slots={{ stepIcon: StatutStepIcon }}>
                          <Typography sx={{ fontSize: 12 }}>
                            {statutMap.get(x.statut)?.label}
                          </Typography>
                          <Typography sx={{ fontSize: 12 }}>
                            {dateUtil.format(x.date, "dd/MM/yyyy")}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid size={12}>
                <Divider />
              </Grid>
            </Grid>
          )
        );
      })}
    </Grid>
  );
}

export default ArticleListStore;
