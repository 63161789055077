import { Container, Grid2 as Grid, Skeleton } from "@mui/material";
import type { ReactElement } from "react";
import CommandeCardSkeleton from "components/molecules/cards/CommandeCard/CommandeCardSkeleton";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";

function FilterCommandesPageSkeleton(): ReactElement {
  return (
    <>
      <PageTitleSkeleton />
      <Container maxWidth="xl">
        <Grid container spacing={2} size={12} sx={{ alignItems: "center" }}>
          <Grid container spacing={2} size={12}>
            <Grid size={12}>
              <Skeleton animation="wave" width={150} height={50} />
            </Grid>
            <Grid size={12}>
              <Skeleton animation="wave" width={200} />
            </Grid>
          </Grid>

          <Grid container spacing={4} size={12}>
            {[...Array(6)].map((v, i) => (
              <Grid key={i} size={{ xs: 12, lg: 6 }}>
                <CommandeCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default FilterCommandesPageSkeleton;
