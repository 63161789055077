import { Grid2 as Grid, Paper, Typography } from "@mui/material";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import type { ReactElement } from "react";
import CommandeHistoryList from "./CommandeHistoryList/CommandeHistoryList";

interface CommandeHistoryProps {
  historyCommandes: Array<CommandeArticleHistory>;
}

function CommandeHistory({ historyCommandes }: CommandeHistoryProps): ReactElement {
  return (
    <Grid container component={Paper} variant="outlined" sx={{ p: 2 }} size={12}>
      <Grid size={12}>
        <Typography variant="subtitle1" color="primary">
          Historique de l'agent
        </Typography>
        <Typography variant="caption">Sur 12 mois</Typography>
      </Grid>
      <Grid size={12}>
        <CommandeHistoryList history={historyCommandes} />
      </Grid>
    </Grid>
  );
}

export default CommandeHistory;
