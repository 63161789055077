import { Container, Grid2 as Grid } from "@mui/material";
import type ApplicationRoles from "constants/ApplicationRoles";
import type Statut from "constants/Statut";
import type Commande from "models/Commande";
import { Authorization } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import graphService from "services/graphService";
import CommandesFilter from "components/molecules/CommandesFilter";
import type Article from "models/Article";
import articlesService from "services/articlesService";
import PageTitle from "components/molecules/PageTitle";
import FilterCommandesPageSkeleton from "./FilterCommandesPageSkeleton";
import { useDispatch, useSelector } from "react-redux";
import type { StoreProps } from "store/store";
import type store from "store/store";
import { getAllCommandes } from "reducers/commandesReducer";
import type { CommandesReducerType } from "reducers/commandesReducer";
import LazyLoadedCommandeList from "./LazyLoadedCommandeListe";

type FilterCommandesPageProps = {
  availableStatuts: Array<Statut>;
  defaultStatuts: Array<Statut>;
  roles: ApplicationRoles | Array<ApplicationRoles>;
  pageTitle?: string;
  getCommandeUrl?: (id: string) => string;
  hasCommandeLink?: boolean;
};

function FilterCommandesPage({
  availableStatuts,
  defaultStatuts,
  roles,
  pageTitle = "",
  getCommandeUrl,
  hasCommandeLink = false,
}: FilterCommandesPageProps): ReactElement {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isReallyInitialLoading, setIsReallyInitialLoading] = useState(true);
  const [filteredCommandes, setFilteredCommandes] = useState<Array<Commande>>([]);
  const [agents, setAgents] = useState<Array<User>>([]);
  const [articles, setArticles] = useState<Array<Article>>([]);

  const dispatch = useDispatch<typeof store.dispatch>();
  const commandes = useSelector<StoreProps, CommandesReducerType>((state) => state?.commandes);

  useEffect(() => {
    async function getCommandes(): Promise<void> {
      setIsInitialLoading(true);
      void dispatch(getAllCommandes());
      setAgents(await graphService.getAllAgents());
      setArticles(await articlesService.getAll());
    }

    void getCommandes();
    // TODO: revoir les dépendances lors d'une mise à jour des règles ESLint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!commandes.isLoading && isInitialLoading) {
      setIsInitialLoading(false);
      setIsReallyInitialLoading(false);
    }
  }, [commandes.isLoading, isInitialLoading]);

  if (isReallyInitialLoading) {
    return <FilterCommandesPageSkeleton />;
  }

  return (
    <Authorization roles={roles}>
      <PageTitle title={pageTitle} />
      <Container maxWidth="xl">
        <Grid container spacing={2} size={12} sx={{ alignItems: "center" }}>
          <CommandesFilter
            commandes={commandes.list}
            agentFilter
            articleFilter
            centreRetraitFilter
            statutFilter
            selectedStatutsDefault={defaultStatuts}
            availableAgents={agents}
            setFilteredCommandes={setFilteredCommandes}
            availableStatuts={availableStatuts}
            availableArticles={articles}
          />
          <LazyLoadedCommandeList
            commandes={filteredCommandes}
            agents={agents}
            hasCommandeLink={hasCommandeLink}
            uri={getCommandeUrl}
          />
        </Grid>
      </Container>
    </Authorization>
  );
}

export default FilterCommandesPage;
