import { Container, Grid2 as Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";
import ArticleCardSkeleton from "components/molecules/cards/ArticleCardSkeleton";
import type { ReactElement } from "react";

const noMarginsOnSmall: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.only("xs")]: {
    p: 0,
    m: 0,
  },
});

function CataloguePageSkeleton(): ReactElement {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <PageTitleSkeleton noBackButton />
      <Container maxWidth={isLargeScreen ? "lg" : "xl"} sx={noMarginsOnSmall}>
        <Grid
          container
          direction="row"
          spacing={isLargeScreen ? 2 : 4}
          size={12}
          sx={{ alignItems: "center" }}>
          <Grid container size={12} sx={{ alignContent: "center", justifyContent: "center" }}>
            <Grid size={8}>
              <Skeleton height="70px" />
            </Grid>
          </Grid>
          {[...Array(5)].map((v, i) => (
            <Grid key={i} size={{ xs: 12, lg: 6 }}>
              <ArticleCardSkeleton />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default CataloguePageSkeleton;
