import { Typography, useMediaQuery, useTheme } from "@mui/material";
import type { SxProps, TypographyProps } from "@mui/material";
import type { ElementType, ReactElement } from "react";

interface BarcodeTypographyProps extends Omit<TypographyProps, "sx"> {
  variant: "barcode1" | "barcode2";
  matricule: string | undefined;
  component?: ElementType;
  sx?: SxProps;
}

function BarcodeTypography({
  variant,
  matricule,
  component,
  sx,
  ...props
}: BarcodeTypographyProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  if (matricule == null) {
    return <Typography>Pas de matricule</Typography>;
  }

  return (
    <Typography
      sx={{ ...sx, fontSize: isSmallScreen ? "2.5rem" : "3rem" }}
      variant={variant}
      {...(component != null ? { component } : {})}
      {...props}>
      *0000{matricule}CO*
    </Typography>
  );
}

export default BarcodeTypography;
