import { Grid2 as Grid, Paper, Skeleton } from "@mui/material";
import { Container } from "@mui/system";
import type { ReactElement } from "react";
import SubTitleCommandeSkeleton from "components/molecules/SubTitleCommandeSkeleton";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";

function CommandePageTemplateSkeleton(): ReactElement {
  return (
    <>
      <PageTitleSkeleton>
        <SubTitleCommandeSkeleton />
      </PageTitleSkeleton>
      <Container maxWidth={false}>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid container sx={{ p: 2, ml: 2 }} component={Paper} variant="outlined" size={12}>
            <Grid container size={12}>
              <Grid size={12}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CommandePageTemplateSkeleton;
