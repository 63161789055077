import {
  Button,
  Grid2 as Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import type { StoreProps } from "store/store";
import { deleteArticle } from "reducers/cartReducer";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import DeleteIcon from "@mui/icons-material/Delete";
import SquareImage from "components/atoms/SquareImage";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import articlesService from "services/articlesService";
import type Article from "models/Article";

function CartTable(): ReactElement {
  const cart = useSelector<StoreProps, Array<CommandeArticleCartModel>>((state) => state?.cart);
  const [articles, setArticles] = useState<Array<Article>>([]);

  useEffect(() => {
    async function getArticles(): Promise<void> {
      try {
        const allArticles = await articlesService.getAll();
        allArticles.sort((p1, p2) => p1.label.localeCompare(p2.label));
        setArticles(allArticles);
      } catch {
        // TODO notify user
        setArticles([]);
      }
    }

    void getArticles();
  }, []);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Article</strong>
              </Typography>
            </TableCell>
            <TableCell sx={isVerySmallScreen ? { paddingX: 0 } : {}}>
              <Typography>
                <strong>{isMediumScreen ? "Qté" : "Quantité"}</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Taille</strong>
              </Typography>
            </TableCell>
            <TableCell align="left" sx={isVerySmallScreen ? { paddingX: 0 } : {}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((article) => (
            <TableRow
              key={article.articleId + article.taille + article.quantite.toString()}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left">
                <Grid container columnSpacing={1} size={12} sx={{ alignItems: "center" }}>
                  <Grid>
                    <SquareImage
                      image={articles?.find((a) => article.articleId === a.id)?.photo.sasUri ?? ""}
                      width={isMediumScreen ? "70px" : "140px"}
                      withBackground
                    />
                  </Grid>
                  <Grid size="grow">
                    <Typography>{article.articleLabel}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Typography>{article.quantite}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{article.taille}</Typography>
              </TableCell>
              <TableCell sx={isVerySmallScreen ? { p: 0 } : {}}>
                {isMediumScreen ? (
                  <IconButton color="error" onClick={() => dispatch(deleteArticle(article))}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => dispatch(deleteArticle(article))}
                    endIcon={<DeleteIcon />}>
                    Supprimer
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
          {cart.length === 0 && (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="center" colSpan={4}>
                <Typography>Votre panier est vide</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CartTable;
