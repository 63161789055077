import { Grid2 as Grid, Typography } from "@mui/material";
import StatutChip from "components/atoms/Chips/StatutChip";
import type { PropsWithChildren, ReactElement } from "react";
import { Authorization } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import type Commande from "models/Commande";
import DisplayMagasinDeRetrait from "./DisplayMagasinDeRetrait";
import DisplayCommandeDate from "./DisplayCommandeDate";
import ApplicationRoles from "constants/ApplicationRoles";
import BarcodeTypography from "components/atoms/BarcodeTypography";

interface SubTitleCommandeProps extends PropsWithChildren {
  demandeur: User | undefined;
  commande: Commande;
}

function SubTitleCommande({ demandeur, commande, children }: SubTitleCommandeProps): ReactElement {
  return (
    <Grid container spacing={1} size={12}>
      <Grid container size={12} sx={{ alignItems: "center", gap: 1 }}>
        <Grid>
          <Typography sx={{ color: "text.primary" }}>
            {demandeur?.employeeId ?? "Pas de Matricule"}
            {" • "}
            {demandeur?.jobTitle ?? "Pas de Fonction"}
          </Typography>
        </Grid>
        <Authorization roles={ApplicationRoles.STOREMANAGER}>
          <Grid size={12}>
            <BarcodeTypography
              variant="barcode2"
              matricule={demandeur?.employeeId}
              sx={{
                display: "inline-block",
                height: "30px",
                verticalAlign: "middle",
                lineHeight: "42px",
              }}
            />
          </Grid>
        </Authorization>
      </Grid>
      <Grid container spacing={2} size={12}>
        <Grid container size="auto" sx={{ alignItems: "center" }}>
          <Grid size="grow">
            <StatutChip
              sx={{ background: "none", pl: 0 }}
              statut={commande.statutCommande}
              variant="body1"
            />
          </Grid>
        </Grid>

        <DisplayCommandeDate item dateCommande={commande.dateCommande} />
        <DisplayMagasinDeRetrait item magasinDeRetrait={commande.magasinDeRetrait} />

        <Grid size="auto" sx={{ textAlign: "end" }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubTitleCommande;
