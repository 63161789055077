import { Badge, Collapse, Grid2 as Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import type { ReactElement } from "react";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import Statut from "constants/Statut";
import { dateUtil } from "@sdeapps/react-core";
import type Article from "models/Article";
import ArticleInput from "components/atoms/inputs/ArticleInput";
import StatutInput from "components/atoms/inputs/StatutInput";
import TaillesInput from "components/atoms/inputs/TaillesInput";
import MagasinDeRetraitInput from "components/atoms/inputs/MagasinDeRetraitInput";
import type CommandeArticleTri from "models/CommandeArticleTri";

type ArticleFiltersProps = {
  betterArticles: Array<CommandeArticleTri>;
  setBetterArticles: (c: Array<CommandeArticleTri>) => void;

  articleFilter?: boolean;
  tailleFilter?: boolean;
  statutFilter?: boolean;
  centreRetraitFilter?: boolean;

  availableArticles?: Array<Article>;
  availableStatuts?: Array<Statut>;

  selectedArticleDefault?: Article;
  selectedTaillesDefault?: Array<string>;
  selectedStatutsDefault?: Array<Statut>;
  selectedCentresRetraitDefault?: Array<string>;
};

const ArticleFilters = memo(function ArticleFilters({
  betterArticles,
  setBetterArticles,

  articleFilter = false,
  tailleFilter = false,
  statutFilter = false,
  centreRetraitFilter = false,

  availableArticles = [],
  availableStatuts = Object.values(Statut),

  selectedArticleDefault,
  selectedTaillesDefault = [],
  selectedStatutsDefault = [],
  selectedCentresRetraitDefault = [],
}: ArticleFiltersProps): ReactElement {
  const [open, setOpen] = useState(true);

  const [selectedArticle, setSelectedArticle] = useState<Article | null>(
    selectedArticleDefault ?? null
  );
  const [selectedTailles, setSelectedTailles] = useState<Array<string>>(selectedTaillesDefault);
  const [selectedStatuts, setSelectedStatuts] = useState<Array<Statut>>(selectedStatutsDefault);
  const [selectedCentresRetrait, setSelectedCentresRetrait] = useState<Array<string>>(
    selectedCentresRetraitDefault
  );
  const [nbrFilter, setNbrFilter] = useState<number>();

  const filterArticles = useCallback(() => {
    let filteredArticles: Array<CommandeArticleTri> = [];

    if (selectedArticle == null) {
      return [];
    }

    filteredArticles = betterArticles.filter((article) => article.articleId === selectedArticle.id);

    if (selectedStatuts.length > 0) {
      filteredArticles = filteredArticles.filter((article) =>
        selectedStatuts.map((c) => c.toLowerCase()).includes(article.statutArticle.toLowerCase())
      );
    }

    if (selectedTailles.length > 0) {
      filteredArticles = filteredArticles.filter((article) =>
        selectedTailles.map((c) => c.toLowerCase()).includes(article.taille.toLowerCase())
      );
    }

    if (selectedCentresRetrait.length > 0) {
      filteredArticles = filteredArticles.filter((article) =>
        selectedCentresRetrait
          .map((c) => c.toLowerCase())
          .includes(article.magasinDeRetrait.toLowerCase())
      );
    }

    filteredArticles.sort((a, b) => dateUtil.compareAsc(a.dateCommande, b.dateCommande));
    return filteredArticles;
  }, [selectedArticle, selectedStatuts, selectedTailles, selectedCentresRetrait, betterArticles]);

  useEffect(() => {
    setSelectedTailles([]);
  }, [selectedArticle]);

  useEffect(() => {
    setBetterArticles(filterArticles());
    setNbrFilter(selectedArticle != null ? 1 + selectedStatuts.length + selectedTailles.length : 0);
    // TODO: revoir les dépendances lors d'une mise à jour des règles ESLint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticle, selectedStatuts, selectedTailles, selectedCentresRetrait, betterArticles]);

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={12}>
        <Badge color="error" badgeContent={nbrFilter}>
          <Button
            variant="contained"
            startIcon={<TuneIcon />}
            onClick={() => {
              setOpen(!open);
            }}>
            Filtres
          </Button>
        </Badge>
      </Grid>
      <Grid container spacing={2} sx={{ margin: 0 }} size={12}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {articleFilter && (
              <Grid size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                <ArticleInput
                  unique
                  availableArticles={availableArticles}
                  selectedArticle={selectedArticle}
                  setSelectedArticle={setSelectedArticle}
                />
              </Grid>
            )}
            {statutFilter && (
              <Grid size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                <StatutInput
                  selectedStatuts={selectedStatuts}
                  setSelectedStatuts={setSelectedStatuts}
                  availableStatuts={availableStatuts}
                  label="État(s) de l'article"
                />
              </Grid>
            )}
            {tailleFilter && (
              <Grid size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                <TaillesInput
                  selectedTailles={selectedTailles}
                  setSelectedTailles={setSelectedTailles}
                  availableTailles={selectedArticle?.tailles ?? []}
                  disabled={
                    selectedArticle?.tailles == null || selectedArticle?.tailles.length <= 0
                  }
                />
              </Grid>
            )}
            {centreRetraitFilter && (
              <Grid size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
                <MagasinDeRetraitInput
                  multiple
                  label="Magasin(s) de retrait"
                  selectedCentresRetrait={selectedCentresRetrait}
                  setSelectedCentresRetrait={setSelectedCentresRetrait}
                />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
});

export default ArticleFilters;
