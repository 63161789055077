import { Box, Grid2 as Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { ReactElement } from "react";
import BadgeIcon from "@mui/icons-material/Badge";
import { Authorization } from "@sdeapps/react-core";
import ApplicationRoles from "constants/ApplicationRoles";

function SubTitleCommandeSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={1}>
      <Grid container size={{ md: 8, xs: 12 }}>
        <Grid size={12}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }}>
            <BadgeIcon fontSize="small" sx={{ color: "#4B515D", marginBottom: "7px" }} />
            <Typography sx={{ color: "text.primary" }}>
              <Skeleton sx={{ display: "inline-block" }} width={75} /> •{" "}
              <Skeleton sx={{ display: "inline-block" }} width={125} />
            </Typography>
          </Box>
        </Grid>
        <Grid size={12}>
          <Typography sx={{ color: "text.primary" }}>
            Statut : <Skeleton width={125} />
          </Typography>
        </Grid>
        <Grid size={{ md: 2, xs: 6 }}>
          <Skeleton width={125} />
        </Grid>
        <Grid size={{ md: "grow", xs: 6 }}>
          <Skeleton width={125} />
        </Grid>
      </Grid>
      <Grid
        container
        size={{ md: 4, xs: 12 }}
        sx={{ justifyContent: isSmallScreen ? "flex-start" : "flex-end" }}>
        <Authorization roles={ApplicationRoles.STOREMANAGER}>
          <Grid size="auto">
            <Typography>Code barre agent :</Typography>
            <Skeleton width={125} />
          </Grid>
        </Authorization>
        <Grid size="auto">
          <Skeleton width={300} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubTitleCommandeSkeleton;
