import { Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import MagasinDeRetraitIcon from "icons/MagasinDeRetraitIcon";
import type { ReactElement } from "react";
import type { TypographyProps } from "@mui/system";

interface DisplayMagasinDeRetraitProps extends Omit<TypographyProps, "sx"> {
  magasinDeRetrait: string;
  item?: boolean;
  noIcon?: boolean;
}

function DisplayMagasinDeRetrait({
  magasinDeRetrait,
  item = false,
  noIcon = false,
  ...typographyProps
}: DisplayMagasinDeRetraitProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container size={item ? "grow" : undefined} sx={{ gap: 1, alignItems: "center" }}>
      {!noIcon && (
        <Grid container size="auto">
          <MagasinDeRetraitIcon fontSize="small" color="primary" />
        </Grid>
      )}
      <Grid size="grow">
        <Typography
          {...typographyProps}
          sx={{ color: "text.primary" }}
          variant={isSmallScreen ? "body2" : "body1"}>
          {!isSmallScreen && "Magasin de retrait : "}
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            component="strong"
            color="primary">
            {magasinDeRetrait}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DisplayMagasinDeRetrait;
