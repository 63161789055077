import { Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { ReactElement } from "react";
import { dateUtil } from "@sdeapps/react-core";
import TodayIcon from "@mui/icons-material/Today";
import type { TypographyProps } from "@mui/system";

interface DisplayCommandeDateProps extends Omit<TypographyProps, "sx"> {
  dateCommande: string | Date;
  item?: boolean;
  noIcon?: boolean;
}

function DisplayCommandeDate({
  dateCommande,
  item = false,
  noIcon = false,
  ...typographyProps
}: DisplayCommandeDateProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container size={item ? "auto" : undefined} sx={{ gap: 1, alignItems: "center" }}>
      {!noIcon && (
        <Grid container size="auto">
          <TodayIcon fontSize="small" color="primary" />
        </Grid>
      )}
      <Grid size="grow">
        <Typography
          {...typographyProps}
          variant={isSmallScreen ? "body2" : "body1"}
          sx={{ color: "text.primary" }}>
          {dateUtil.format(dateCommande, "dd/MM/yyyy")}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DisplayCommandeDate;
