import { Container, Grid2 as Grid } from "@mui/material";
import type ApplicationRoles from "constants/ApplicationRoles";
import type Statut from "constants/Statut";
import type Commande from "models/Commande";
import { dateUtil, Authorization } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import graphService from "services/graphService";
import commandesService from "services/commandesService";
import type Article from "models/Article";
import articlesService from "services/articlesService";
import PageTitle from "components/molecules/PageTitle";
import ArticleFilters from "components/molecules/ArticleFilters";
import TriByArticleList from "./TriByArticleList";
import type CommandeArticleTri from "models/CommandeArticleTri";

type FilterCommandesPageProps = {
  availableStatuts: Array<Statut>;
  defaultStatuts?: Array<Statut>;
  roles: ApplicationRoles | Array<ApplicationRoles>;
  pageTitle?: string;
};

function FilterArticlesPage({
  availableStatuts,
  defaultStatuts,
  roles,
  pageTitle = "",
}: FilterCommandesPageProps): ReactElement {
  const [catalogArticles, setCatalogArticles] = useState<Array<Article>>([]);
  const [agents, setAgents] = useState<Array<User>>([]);
  const [allCommandArticles, setAllCommandArticles] = useState<Array<CommandeArticleTri>>([]);
  const [filteredArticles, setFilteredArticles] = useState<Array<CommandeArticleTri>>([]);

  const [isFetchingAndFiltering, setIsFetchingAndFiltering] = useState(true);

  async function getAllCommandes(): Promise<Array<Commande>> {
    const allCommandes = await commandesService.getAll();
    allCommandes.sort((a, b) => dateUtil.compareAsc(a.dateCommande, b.dateCommande));
    return allCommandes;
  }

  async function getAllBetterCommandArticles(): Promise<Array<CommandeArticleTri>> {
    const allCommandes = await getAllCommandes();

    let allBetterCommandesArticles: Array<CommandeArticleTri> = [];
    allCommandes.forEach((commande) => {
      commande.articles.forEach((article) => {
        allBetterCommandesArticles.push({
          ...article,
          magasinDeRetrait: commande.magasinDeRetrait,
        });
      });
    });

    allBetterCommandesArticles = allBetterCommandesArticles.filter((article) =>
      availableStatuts.map((c) => c.toLowerCase()).includes(article.statutArticle.toLowerCase())
    );

    return allBetterCommandesArticles;
  }

  useEffect(() => {
    setIsFetchingAndFiltering(true);
    async function getData(): Promise<void> {
      try {
        const allArticles = await articlesService.getAll();
        allArticles.sort((p1, p2) => p1.label.localeCompare(p2.label));
        setCatalogArticles(allArticles);

        setAllCommandArticles(await getAllBetterCommandArticles());

        setAgents(await graphService.getAllAgents());
      } catch {
        // TODO error handling
        setCatalogArticles([]);
        setAllCommandArticles([]);
        setAgents([]);
      }
      setIsFetchingAndFiltering(false);
    }

    void getData();
    // TODO: revoir les dépendances lors d'une mise à jour des règles ESLint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Authorization roles={roles}>
      <PageTitle title={pageTitle} />
      <Container maxWidth={false}>
        <Grid container spacing={2} size={12} sx={{ alignItems: "center" }}>
          <Grid size={12}>
            <ArticleFilters
              betterArticles={allCommandArticles}
              setBetterArticles={setFilteredArticles}
              articleFilter
              tailleFilter
              statutFilter
              centreRetraitFilter
              availableArticles={catalogArticles}
              availableStatuts={availableStatuts}
              selectedStatutsDefault={defaultStatuts}
            />
          </Grid>
          <Grid size={12}>
            <TriByArticleList
              articles={filteredArticles}
              agents={agents}
              isLoading={isFetchingAndFiltering}
            />
          </Grid>
        </Grid>
      </Container>
    </Authorization>
  );
}

export default FilterArticlesPage;
