import { CircularProgress, Grid2 as Grid } from "@mui/material";
import type { ReactElement } from "react";

function LoadingPage(): ReactElement {
  return (
    <Grid container size={12} sx={{ justifyContent: "center", flexGrow: 1 }}>
      <Grid sx={{ marginY: "20vh" }} size={12}>
        <CircularProgress size="15vh" />
      </Grid>
    </Grid>
  );
}

export default LoadingPage;
