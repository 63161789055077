import {
  Card,
  CardActions,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo } from "react";
import type { ReactElement } from "react";

const ArticleCardSkeleton = memo(function ArticleCardSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Card variant="outlined" sx={{ border: "none" }}>
      <CardContent>
        <Grid container spacing={isSmallScreen ? 1 : 2} size={12} sx={{ alignItems: "flex-start" }}>
          <Grid
            size={12}
            sx={{ position: "relative", m: "none" }}
            {...(isSmallScreen && { container: true, justifyContent: "center" })}>
            <Skeleton
              variant="circular"
              width={isSmallScreen ? "120px" : "140px"}
              height={isSmallScreen ? "120px" : "140px"}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: "grow" }}>
            <Typography>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          spacing={1}
          size={12}
          sx={{ alignItems: "center", justifyContent: "flex-end" }}>
          <Grid sx={{ minWidth: "100px" }} size={isSmallScreen ? "grow" : undefined}>
            <Skeleton height="50px" />
          </Grid>
          <Grid size={isSmallScreen ? "grow" : undefined} sx={{ minWidth: "160px" }}>
            <Skeleton height="50px" />
          </Grid>
          <Grid size={isSmallScreen ? "grow" : undefined} sx={{ minWidth: "130px" }}>
            <Skeleton height="50px" />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
});

export default ArticleCardSkeleton;
