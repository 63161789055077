import { Grid2 as Grid, Typography, useTheme, useMediaQuery, Skeleton } from "@mui/material";
import { Container } from "@mui/system";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";
import type { ReactElement } from "react";

function InformationsPratiquesSkeleton(): ReactElement {
  return (
    <Grid container spacing={2} size={12}>
      <Grid size={{ xs: 4, md: 2 }}>
        <Skeleton animation="wave" />
      </Grid>
      <Grid size={{ xs: 12, md: 10 }}>
        <Skeleton animation="wave" />
      </Grid>
    </Grid>
  );
}

function ArticleInformationSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <PageTitleSkeleton />
      <Container>
        <Grid container spacing={2} size={12}>
          <Grid
            container
            size={{ md: 3, xs: 12 }}
            spacing={2}
            sx={{ alignContent: "center", justifyContent: "center" }}>
            <Grid container size={12} sx={{ justifyContent: "center", position: "relative" }}>
              <Skeleton
                variant="circular"
                animation="wave"
                width={isSmallScreen ? 120 : 140}
                height={isSmallScreen ? 120 : 140}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} size={{ md: 9, xs: 12 }}>
            <Grid size={12}>
              <Typography>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Typography>
            </Grid>

            <Grid container spacing={1} sx={{ alignItems: "center", justifyContent: "flex-end" }}>
              <Grid size={isSmallScreen ? "grow" : undefined} sx={{ width: "160px" }}>
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid size={isSmallScreen ? "grow" : undefined} sx={{ width: "160px" }}>
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid size={isSmallScreen ? "grow" : undefined} sx={{ width: "160px" }}>
                <Skeleton animation="wave" height={60} />
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Typography variant="h3">
              <Skeleton animation="wave" width={isSmallScreen ? "50%" : 300} />
            </Typography>
          </Grid>

          <InformationsPratiquesSkeleton />
          <InformationsPratiquesSkeleton />
          <InformationsPratiquesSkeleton />
        </Grid>
      </Container>
    </>
  );
}

export default ArticleInformationSkeleton;
